import React from "react";
import styles from "./ChangeDetails.module.css";
import defaultImage from "../../../assets/defaultImage.jpg";
import { IconTag } from "../../IconTag";

function ChangeDetails({
  divClassName,
  changePlatform,
  changeMessage,
  changeBy,
  changeTime,
  platformTagImage,
  changeByTagImage,
  changeTimeTagImage,
  platformTagClassName,
  changeByTagClassName,
  changeTimeClassName,
  changeTextClassName,
  ...props
}) {
  const defaultDivClassName = "change-details";
  const divClasses = `${styles[defaultDivClassName]} ${divClassName}`;

  const defaultPlatformTagClassName = "platform-tag";
  const platformTagClasses = `${styles[defaultPlatformTagClassName]} ${platformTagClassName}`;

  const defaultChangeByClassName = "change-by-tag";
  const changeByClasses = `${styles[defaultChangeByClassName]} ${changeByTagClassName}`;

  const defaultChangeTimeClassName = "change-time-tag";
  const changeTimeClasses = `${styles[defaultChangeTimeClassName]} ${changeTimeClassName}`;

  const defaultChangeTextClassName = "change-text";
  const changeTextClasses = `${styles[defaultChangeTextClassName]} ${changeTextClassName}`;
  return (
    <div className={divClasses} {...props}>
      <IconTag
        tagImage={platformTagImage}
        spanProps={{ className: platformTagClasses }}
        divProps={{ className: styles["platform-tag-container"] }}
      >
        {changePlatform}
      </IconTag>
      <span className={changeTextClasses}>{changeMessage}</span>
      <IconTag
        tagImage={changeByTagImage}
        spanProps={{ className: changeByClasses }}
      >
        {changeBy}
      </IconTag>
      <IconTag
        tagImage={changeTimeTagImage}
        spanProps={{ className: changeTimeClasses }}
      >
        {changeTime}
      </IconTag>
    </div>
  );
}

ChangeDetails.defaultProps = {
  divClassName: "",
  changePlatform: "Platform name not found",
  changeMessage: "Message not found",
  changeBy: "Change Author Not found",
  changeTime: "Change Time Not Founn",
  platformTagImage: defaultImage,
  changeByTagImage: defaultImage,
  changeTimeTagImage: defaultImage,
  platformTagClassName: "",
  changeByTagClassName: "",
  changeTimeClassName: "",
};

export default ChangeDetails;
