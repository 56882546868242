import React from "react";
import styles from "./Message.module.css";
import SenderDetails from "../SenderDetails/SenderDetails";

function Message({ children, isCopilot }) {
  const messageContainer = isCopilot
    ? "copilot-message-container"
    : "sender-message-container";
  const messageContent = isCopilot ? "copilot-message" : "sender-message";
  return (
    <div>
      <SenderDetails isCopilot={isCopilot} />
      <br />
      <div className={styles[messageContainer]}>
        <span className={styles[messageContent]}>{children}</span>
      </div>
    </div>
  );
}

Message.defaultProps = {
  children: "Hey There",
  isCopilot: false,
};

export default Message;
