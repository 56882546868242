import React from "react";
import styles from "./Tag.module.css";

function Tag({ children, className, ...props }) {
  // Default class names
  const defaultSpanClassName = "tag";
  // Merge default class names with provided class names
  const spanClassName = `${styles[defaultSpanClassName]} ${className || ""}`;

  return (
    <div>
      <span {...props} className={spanClassName}>
        {children}
      </span>
    </div>
  );
}

Tag.defaultProps = {
  children: "Text here",
  className: "",
};

export default Tag;
