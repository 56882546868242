import React from "react";
import styles from "./ChangeDetailsChat.module.css";
import { IconTag } from "../../IconTag";
import defaultImage from "../../../assets/defaultImage.jpg";

function ChangeDetailsChat({
  className,
  changeDetailsTitle,
  resourceName,
  changeDetailsProviderImage,
  changeDetailsProvider,
  ...otherProps
}) {
  const changeDetailsClass = styles["change-details-container"];
  const greyTextClass = styles["grey-text"];
  const colonTextClass = styles["colon-text1"];
  const text600Class = styles["text-600"];
  const changeNameClass = styles["resource-name"];
  const colonText2Class = styles["colon-text2"];
  const colonText3Class = styles["colon-text3"];
  const text400Class = styles["text-400"];

  return (
    <div className={className} {...otherProps}>
      <div className={changeDetailsClass}>
        <span className={greyTextClass}>Title</span>
        <span className={`${greyTextClass} ${colonTextClass}`}>:</span>
        <span className={text600Class}>{changeDetailsTitle}</span>
      </div>
      <div className={changeNameClass}>
        <span className={greyTextClass}>Resource</span>
        <span className={`${greyTextClass} ${colonText2Class}`}>:</span>
        <span className={text600Class}>{resourceName}</span>
      </div>
      <div className={changeDetailsClass}>
        <span className={greyTextClass}>Provider</span>
        <span className={`${greyTextClass} ${colonText3Class}`}>:</span>
        <IconTag
          tagImage={changeDetailsProviderImage}
          spanProps={{ className: text400Class }}
        >
          {changeDetailsProvider}
        </IconTag>
      </div>
    </div>
  );
}

ChangeDetailsChat.defaultProps = {
  changeDetailsTitle: "Updated frontend with latest packages",
  resourceName: "Frontend",
  changeDetailsProviderImage: defaultImage,
  changeDetailsProvider: "07:58 AM",
};

export default ChangeDetailsChat;
