import React from "react";
import styles from "./ResourceChange.module.css";
import defaultImage from "../../../assets/defaultImage.jpg";
import IconTag from "../../IconTag/IconTag";
function ResourceChange({
  platformTagImage,
  userAvatar,
  timeTagImage,
  platformTagName,
  platformtagClassName,
  changeText,
  ownedBy,
  amount,
  currency,
  changedTime,
  size,
  className,
  ...props
}) {
  const defaultContainerClassName = "change-container";
  const containerClassNames = `${styles[defaultContainerClassName]} ${className}`;
  return (
    <div className={containerClassNames} {...props}>
      <IconTag
        tagImage={platformTagImage}
        spanProps={{ className: `${styles["platform-tag"]}` }}
        divProps={{ className: styles["platform-tag-container"] }}
      >
        {platformTagName}
      </IconTag>
      <span className={`${styles["change-text"]}`}>{changeText}</span>
      <IconTag
        tagImage={userAvatar}
        spanProps={{ className: `${styles["change-by-tag"]}` }}
      >
        {ownedBy}
      </IconTag>
      <span className={styles["cost"]}>
        <span className={styles["cost-text"]}>{"Cost : "}</span>
        <span className={styles["dollar-text"]}>{currency}</span>
        <span className={styles["amount-text"]}>{amount}</span>
      </span>
      <IconTag
        tagImage={timeTagImage}
        spanProps={{ className: styles["change-time-tag"] }}
      >
        {changedTime}
      </IconTag>
      <span className={styles["cost"]}>
        <span className={styles["cost-text"]}>{"Size: "}</span>
        <span className={styles["amount-text"]}>{size}</span>
      </span>
    </div>
  );
}

ResourceChange.defaultProps = {
  platformTagImage: defaultImage,
  timeTagImage: defaultImage,
  userAvatar: defaultImage,
  platformTagName: "Microservice Demo",
  changeText: "resource owned by",
  ownedBy: "Jaya Krishnan",
  amount: "400",
  currency: "$",
  changedTime: "7:58 AM",
  size: "3GB",
};
export default ResourceChange;
