import React from "react";
import styles from "./SideButton.module.css";
import fullscreen from "./assets/fullscreen.svg";
import star from "./assets/star.svg";
function SideButtons() {
  return (
    <div className={styles["side-btn-container"]}>
      <img src={star} />
      <img src={fullscreen} />
    </div>
  );
}

export default SideButtons;
