import React, { useState } from "react";
import styles from "./ChatTextBox.module.css";
import sendButton from "./assets/PaperPlaneRight.svg";
import star from "./assets/Star.svg";
import { Tag } from "../../Tag";

function ChatTextBox({ suggestions, ...props }) {
  const [message, setMessage] = useState("");
  const chatBoxClass = "chatbox-container";
  const sendButtonClass = "send-image";
  const suggestionContainerClass = "suggestions-container";
  const starImageClass = "star";
  const suggestionTagClass = "suggestion-tag";
  return (
    <div className={`${styles[chatBoxClass]} ${styles[props.className]}`}>
      <input
        type="text"
        value={message}
        className={styles["inputbox"]}
        onChange={(e) => {
          setMessage(e.target.value);
        }}
      ></input>
      <img
        src={sendButton}
        alt="sendButton"
        className={styles[sendButtonClass]}
      />
      <div className={styles[suggestionContainerClass]}>
        <img src={star} alt="star" className={styles[starImageClass]} />
        {suggestions.map((suggestion) => {
          return (
            <>
              <Tag className={styles[suggestionTagClass]}>{suggestion}</Tag>
            </>
          );
        })}
      </div>
    </div>
  );
}

ChatTextBox.defaultProps = {
  suggestions: [
    "Show me more related incidents",
    " Show me related logs from nexus-front-end",
  ],
  className: "",
};

export default ChatTextBox;
