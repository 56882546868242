import relic from "./assets/demo-assets/relic.png";
import kubernetes from "./assets/demo-assets/kubernetes-logo.png";
import github from "./assets/demo-assets/github.svg";
import avatar from "./assets/demo-assets/man-avatar.svg";
import timeAvatar from "./assets/demo-assets/a-time.svg";
import onepaneaiCofounder from "./assets/demo-assets/Ellipse 2603.svg";
import onepaneaiAvatar from "./assets/demo-assets/Ellipse 2602.svg";
export const incidents = [
  {
    id: 1,
    incidentMessage: "Traffic Volume Exceeded Threshold on",
    incidentType: "High Traffic Alert",
    incidentPlatform: "nexust-frontend",
    incidentTime: "08:50 AM",
    incidentTagImage: relic,
  },
  {
    id: 2,
    incidentMessage: "Error Frequency Surpassed Threshold on",
    incidentType: "Error Rate Alert",
    incidentPlatform: "nexust-frontend",
    incidentTime: "08:50 AM",
    incidentTagImage: relic,
  },
  {
    id: 3,
    incidentMessage: "Error Frequency Surpassed Threshold on",
    incidentType: "Error Rate Alert",
    incidentPlatform: "nexust-frontend",
    incidentTime: "08:50 AM",
    incidentTagImage: relic,
  },
];

export const changeList = [
  {
    id: 1,
    changePlatform: "nexus-frontend-deplyment",
    changeMessage: "deployed by",
    changeBy: "Jaya Krishnan",
    changeTime: "07:58 AM",
    platformTagImage: kubernetes,
    changeByTagImage: avatar,
    changeTimeTagImage: timeAvatar,
  },
  {
    id: 2,
    changePlatform: "vnet-nexussaas-prod-eastus-prod",
    changeMessage: "configuration updated by",
    changeBy: "Jaya Krishnan",
    changeTime: "07:58 AM",
    platformTagImage: kubernetes,
    changeByTagImage: avatar,
    changeTimeTagImage: github,
  },
  {
    id: 3,
    changePlatform: "nexus-frontend-deplyment",
    changeMessage: "deployed by",
    changeBy: "Jaya Krishnan",
    changeTime: "07:58 AM",
    platformTagImage: kubernetes,
    changeByTagImage: avatar,
    changeTimeTagImage: timeAvatar,
  },
  {
    id: 4,
    changePlatform: "vnet-nexussaas-prod-eastus-prod",
    changeMessage: "configuration updated by",
    changeBy: "Jaya Krishnan",
    changeTime: "07:58 AM",
    platformTagImage: kubernetes,
    changeByTagImage: avatar,
    changeTimeTagImage: github,
  },
];

export const resourceChangeList = [
  {
    id: 1,
    platformTagImage: kubernetes,
    userAvatar: avatar,
    timeTagImage: timeAvatar,
    platformTagName: "Microservice Demo",
    changeText: "resource owned by",
    ownedBy: "Jaya Krishnan",
    amount: "400",
    currency: "$",
    changedTime: "07:58 AM",
    size: "3GB",
  },
  {
    id: 2,
    platformTagImage: kubernetes,
    userAvatar: avatar,
    timeTagImage: timeAvatar,
    platformTagName: "Microservice Demo",
    changeText: "resource owned by",
    ownedBy: "Jaya Krishnan",
    amount: "400",
    currency: "$",
    changedTime: "07:58 AM",
    size: "3GB",
  },
  {
    id: 3,
    platformTagImage: kubernetes,
    userAvatar: avatar,
    timeTagImage: timeAvatar,
    platformTagName: "Microservice Demo",
    changeText: "resource owned by",
    ownedBy: "Jaya Krishnan",
    amount: "400",
    currency: "$",
    changedTime: "07:58 AM",
    size: "3GB",
  },
  {
    id: 4,
    platformTagImage: kubernetes,
    userAvatar: avatar,
    timeTagImage: timeAvatar,
    platformTagName: "Microservice Demo",
    changeText: "resource owned by",
    ownedBy: "Jaya Krishnan",
    amount: "400",
    currency: "$",
    changedTime: "07:58 AM",
    size: "3GB",
  },
];

export const tileDataList = [
  {
    id: 1,
    children: "Nexus Saas",
    count: "2",
    color: "#FDBC3E",
  },
  {
    id: 2,
    children: "Nexus Portal",
    count: "0",
    color: "#2DA077",
  },
  {
    id: 3,
    children: "Online Boutique",
    count: "0",
    color: "#2DA077",
  },
  {
    id: 4,
    children: "Other",
    count: "1",
    color: "#4629F2",
  },
];

export const ResourceDetailsList = [
  {
    id: 1,
    resourceName: "Microservice Demo",
    resourceEnvironment: "Dev",
    resourceOwner: "Jaya Krishnan",
    resourceHealth: "Healthy",
    resourceService: "Online Store",
    resourceCriticality: "High",
    resourceHealthChipColor: "#2DA077",
    resourceHealthChipBackgroundColor: "E7F3F0",
    resourceServiceChipColor: "#2DA077",
    resourceServiceChipBackgroundColor: "#E7F3F0",
    resourceCriticalityChipColor: "#D94F4F",
    resourceCriticalityChipBackgroundColor: "#F5D9DA",
    resourceNameImage: kubernetes,
    userAvatar: avatar,
  },
];

export const description = {
  text: "Kubernetes, nicknamed K8s, is an open-source platform that acts as a traffic conductor for containerized applications. It automates their deployment, scaling, and management. Think of it like an orchestra conductor for tiny software programs called containers, ensuring they run smoothly and efficiently across multiple computers.",
  list: [
    "Automatic scaling: Need more containers to handle traffic? K8s spins them up automatically. Less traffic? It scales them down to save resources.",
    "Self-healing: A container crashes? K8s restarts it, keeping your application running smoothly.",
    "Load balancing: K8s distributes traffic evenly across your containers, ensuring none get overloaded.",
  ],
};

export const changeDetailsProviderImage = github;
// import onepaneaiAvatar from "./assets/img/onepaneai-avatar.png";
// import onepaneaiCofounder from "./assets/img/onepane-cofounder.jpg";
// import jayaKrishnanAvatar from "./assets/img/man-avatar.webp";
// import aTimeAvatar from "./assets/img/a-time-avatar.jpg";
// import githubLogo from "./assets/img/github-logo.png";
// import kubernetesLogo from "./assets/img/kubernetes-logo.png";

export const users = [
  {
    name: "Jaya Krishnan",
    userAvatar: avatar,
  },
];

export const incidents1 = [
  {
    id: 1,
    incidentType: "High Traffic Alert",
    incidentMessage: "Traffic Volume  Exceeded Threshold on",
    incidentPlatform: "nexust-frontend",
    incidentTime: "8:50 AM",
    incidentTagImage: relic,
  },
  {
    id: 2,
    incidentType: "Error Rate Alert",
    incidentMessage: "Error Frequency Surpassed Threshold on",
    incidentPlatform: "nexust-frontend",
    incidentTime: "8:50 AM",
    incidentTagImage: relic,
  },
];

export const resourceChanges = [
  {
    id: 1,
    changePlatform: "nexus-frontend-deplyment",
    platformLogo: kubernetes,
    changeType: "deployed by",
    changeBy: users[0].name,
    changeUserAvatar: users[0].userAvatar,
    changeTime: "7:58 AM",
    changeTimeAvatar: timeAvatar,
  },
  {
    id: 2,
    changePlatform: "vnet-nexussaas-prod-eastus-prod",
    platformLogo: kubernetes,
    changeType: "configuration updated by",
    changeBy: users[0].name,
    changeUserAvatar: users[0].userAvatar,
    changeTime: "7:58 AM",
    changeTimeAvatar: github,
  },
];

export const messages = [
  {
    id: 1,
    isCopilot: false,
    text: "Show me the critical incident with open status for nexus Saas application",
    username: "You",
    timestamp: "Just Now",
    userAvatar: onepaneaiCofounder,
  },
  {
    id: 2,
    isCopilot: true,
    text: "Here are the currently open incidents from Nexus Saas:",
    username: "Copilot",
    timestamp: "Just Now",
    userAvatar: onepaneaiAvatar,
    incidents: incidents1,
  },
  {
    id: 3,
    isCopilot: false,
    text: "What are the latest updates to associated resources?",
    username: "You",
    timestamp: "Just Now",
    userAvatar: onepaneaiCofounder,
  },
  {
    id: 4,
    isCopilot: true,
    text: "See the data below for recent changes to related resources",
    username: "Copilot",
    timestamp: "Just Now",
    userAvatar: onepaneaiAvatar,
    resourceChanges,
  },
];
