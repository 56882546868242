import React from "react";
import Incident from "../Incident/Incident";
import styles from "./ChatWindow.module.css";
import ChatHeader from "../ChatHeader/ChatHeader";
import SenderMessage from "../Message/Message";
import ChangeDetails from "../ChangeDetails/ChangeDetails";
import ChatTextBox from "../ChatTextBox/ChatTextBox";

function ChatWindow({ converstion, ...props }) {
  const chatContainerClass = "chat-container";
  const messageContainerClass = "message-container";
  const incidentClass = "incident";
  const changeComponentClass = "change-component";
  return (
    <div className={styles[chatContainerClass]}>
      <ChatHeader />
      <div className={styles[messageContainerClass]}>
        {converstion.map((message) => {
          return (
            <>
              {message.isCopilot ? (
                <>
                  <SenderMessage isCopilot={true}>{message.text}</SenderMessage>
                  {message.incidents
                    ? message.incidents.map((incident) => {
                        return (
                          <>
                            <Incident
                              className={styles[incidentClass]}
                              incidentType={incident.incidentType}
                              incidentPlatform={incident.incidentPlatform}
                              incidentTime={incident.incidentTime}
                              incidentTagImage={incident.incidentTagImage}
                            >
                              {incident.incidentMessage}
                            </Incident>
                          </>
                        );
                      })
                    : ""}

                  {message.resourceChanges
                    ? message.resourceChanges.map((change) => {
                        return (
                          <>
                            <ChangeDetails
                              divClassName={styles[changeComponentClass]}
                              key={change.id}
                              changePlatform={change.changePlatform}
                              changeMessage={change.changeType}
                              changeBy={change.changeBy}
                              changeTime={change.changeTime}
                              changeByTagImage={change.changeUserAvatar}
                              platformTagImage={change.platformLogo}
                              changeTimeTagImage={change.changeTimeAvatar}
                            ></ChangeDetails>
                          </>
                        );
                      })
                    : ""}
                </>
              ) : (
                <SenderMessage>{message.text}</SenderMessage>
              )}
            </>
          );
        })}
        <ChatTextBox />
      </div>
    </div>
  );
}

// <SenderMessage>
//           Show me the critical incident with open status for nexus Saas
//           application
//         </SenderMessage>
//         <SenderMessage isCopilot={true}>
//           Here are the currently open incidents from Nexus SaaS:
//         </SenderMessage>
//         <Incident
//           className={styles[incidentClass]}
//           incidentType={"High Traffic Alert"}
//           incidentPlatform={"nexust-frontend"}
//           incidentTime={"08:50 AM"}
//           incidentTagImage={relic}
//         >
//           Traffic Volume Exceeded Threshold on
//         </Incident>
//         <Incident
//           className={styles["incident"]}
//           incidentType={"Error Rate Alert"}
//           incidentPlatform={"nexust-frontend"}
//           incidentTime={"08:50 AM"}
//           incidentTagImage={relic}
//         >
//           Error Frequency Surpassed Threshold on
//         </Incident>
//         <SenderMessage>
//           What are the latest updates to associated resources?
//         </SenderMessage>
//         <SenderMessage isCopilot={true}>
//           See the data below for recent changes to related resources
//         </SenderMessage>
//         <ChangeDetails
//           divClassName={styles[changeComponentClass]}
//           key={1}
//           changePlatform={"nexus-frontend-deplyment"}
//           changeMessage={"deployed by"}
//           changeBy={"Jaya Krishnan"}
//           changeTime={"07:58AM"}
//           changeByTagImage={manAvatar}
//           platformTagImage={kubernetes}
//           changeTimeTagImage={atimeAvatar}
//         ></ChangeDetails>
//         <ChangeDetails
//           divClassName={styles["change-component"]}
//           key={1}
//           changePlatform={"vnet-nexussaas-prod-eastus-prod"}
//           changeMessage={"configuration updated by"}
//           changeBy={"Jaya Krishnan"}
//           changeTime={"07:58AM"}
//           changeByTagImage={manAvatar}
//           platformTagImage={kubernetes}
//           changeTimeTagImage={github}
//         ></ChangeDetails>

export default ChatWindow;
