import React from "react";
import { Tag } from "../../Tag";
import { IconTag } from "../../IconTag";
import atime from "../../../assets/demo-assets/a-time.svg";
import styles from "./IncidentDetails.module.css";
function IncidentDetails({
  className,
  incidentId,
  incidentName,
  incidentSourceTime,
  incidentSourceTagTimeImage,
  ...props
}) {
  return (
    <div className={className} {...props}>
      <div className={styles["incident-details-container"]}>
        <span className={styles["grey-text"]}>Incident ID</span>
        <span className={`${styles["grey-text"]} ${styles["colon-text1"]}`}>
          :
        </span>

        <Tag className={styles["text-600"]}>{incidentId}</Tag>
      </div>
      <div className={styles["incident-name"]}>
        <span className={styles["grey-text"]}>Name</span>
        <span className={`${styles["grey-text"]} ${styles["colon-text2"]}`}>
          :
        </span>
        <span className={styles["text-600"]}>{incidentName}</span>
      </div>
      <div className={styles["incident-details-container"]}>
        <span className={styles["grey-text"]}>Source</span>
        <span className={`${styles["grey-text"]} ${styles["colon-text3"]}`}>
          :
        </span>
        <IconTag
          tagImage={incidentSourceTagTimeImage}
          spanProps={{ className: styles["text-400"] }}
        >
          {incidentSourceTime}
        </IconTag>
      </div>
    </div>
  );
}

IncidentDetails.defaultProps = {
  incidentId: "#1345",
  incidentName:
    "Error rate was much different than normal Is Ready < 1.0 for at...",
  incidentSourceTime: "07:58 AM",
  incidentSourceTagTimeImage: atime,
};

export default IncidentDetails;
