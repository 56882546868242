import React from "react";
import styles from "./Chip.module.css";

function Chip({ children, color, bgColor, className, ...props }) {
  const defaultChipContainerClassName = "chip-container";
  const chipContainerClassNames = `${styles[defaultChipContainerClassName]} ${className}`;

  return (
    <div
      className={chipContainerClassNames}
      style={{ color: color, backgroundColor: bgColor }}
      {...props}
    >
      {children}
    </div>
  );
}

export default Chip;
