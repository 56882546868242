import React from "react";
import copilot from "./assets/copilot.svg";
import onepaneLogo from "./assets/onepane-logo.svg";
import crossSign from "./assets/cross-sign.svg";
import styles from "./ChatHeader.module.css";

function ChatHeader({ logo, textImage, ...props }) {
  const headerClassName = `header`;
  const onepaneImageClassName = "onepane-image";
  const copilotImageClassName = "copilot-image";
  const crossSignImageClassName = "cross-sign-image";

  return (
    <div className={`${styles[headerClassName]} ${styles[props.className]}`}>
      <img
        src={onepaneLogo}
        alt="onepane-logo"
        className={styles[onepaneImageClassName]}
      />
      <img
        src={copilot}
        className={styles[copilotImageClassName]}
        alt="copilot"
      />
      <img
        src={crossSign}
        className={styles[crossSignImageClassName]}
        alt="close button"
      />
    </div>
  );
}

ChatHeader.defaultProps = {
  logo: onepaneLogo,
  textImage: copilot,
};

export default ChatHeader;
