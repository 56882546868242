import React from "react";
import defaultImage from "../../../assets/defaultImage.jpg";
import styles from "./SenderDetails.module.css";
import copilot from "../../../assets/demo-assets/Ellipse 2602.svg";
import founder from "../../../assets/demo-assets/Ellipse 2603.svg";

function SenderDetails({
  messageTimestamp,
  senderName,
  isCopilot,
  userAvatar,
  ...props
}) {
  const messageContainer = "sender-container";
  const timestampClass = isCopilot ? "timestamp-copilot" : "timestamp-sender";
  const nameClass = isCopilot ? "copilot-name" : "sender-name";
  const imageClass = isCopilot ? "defaultImage-copilot" : "defaultImage-sender";
  const imageTag = isCopilot ? (
    <img src={copilot} alt="copilot logo" className={styles[imageClass]} />
  ) : (
    <img src={userAvatar} alt="user avatar" />
  );

  const timestampTag = (
    <span className={styles[timestampClass]}>{messageTimestamp}</span>
  );

  const nameTag = (
    <span className={styles[nameClass]}>
      {isCopilot ? "Copilot" : senderName}
    </span>
  );

  return (
    <div
      className={`${styles[messageContainer]} ${styles[props.className]}`}
      {...props}
    >
      {isCopilot ? (
        <>
          {imageTag}
          {nameTag}
          {timestampTag}
        </>
      ) : (
        <>
          {timestampTag}
          {nameTag}
          {imageTag}
        </>
      )}
    </div>
  );
}

SenderDetails.defaultProps = {
  messageTimestamp: "Just Now",
  senderName: "You",
  isCopilot: true,
  userAvatar: founder,
};

export default SenderDetails;
