import { Tag } from "./components/Tag";
import { Incident } from "./components/Chat/Incident";
import ChangeComponent from "./components/Chat/ChangeDetails/ChangeDetails";
import Tile from "./components/Tile/Tile";
import {
  incidents,
  changeList,
  resourceChangeList,
  tileDataList,
  ResourceDetailsList,
  description,
  changeDetailsProviderImage,
} from "./constants";
import ResourceChange from "./components/Chat/ResourceChange/ResourceChange";
import ResourceDetails from "./components/Chat/ResourceDetails/ResourceDetails";
import { Description } from "./components/Description";
import { IncidentDetails } from "./components/Chat/IncidentDetails";
import { ChangeDetailsChat } from "./components/Chat/ChangeDetailsChat";
import ChatWindow from "./components/Chat/ChatWindow/ChatWindow";
import { messages } from "./constants";
import MessageBoxComponent from "./components/MessageBox/MessageBox";
import SideButtons from "./components/SideButtons/SideButtons";

function App() {
  return (
    <>
      {incidents.map((incident) => {
        return (
          <>
            <Incident
              key={incident.id}
              incidentType={incident.incidentType}
              incidentPlatform={incident.incidentPlatform}
              incidentTagImage={incident.incidentTagImage}
              incidentTime={incident.incidentTime}
            >
              {incident.incidentMessage}
            </Incident>
            <br></br>
            <br></br>
          </>
        );
      })}
      <br />
      {/* changePlatform, changeMessage, changeBy, changeTime, platformTagImage,
      changeByTagImage, changeTimeTagImage, */}
      {changeList.map((change) => {
        return (
          <>
            <ChangeComponent
              key={change.id}
              changePlatform={change.changePlatform}
              changeMessage={change.changeMessage}
              changeBy={change.changeBy}
              changeTime={change.changeTime}
              platformTagImage={change.platformTagImage}
              changeByTagImage={change.changeByTagImage}
              changeTimeTagImage={change.changeTimeTagImage}
            ></ChangeComponent>
            <br />
            <br />
          </>
        );
      })}
      <br />
      {/* platformTagImage, userAvatar, timeTagImage, platformTagName,changeText,
      ownedBy, amount, currency, changedTime, size, */}
      {resourceChangeList.map((resourceChange) => {
        return (
          <>
            <ResourceChange
              key={resourceChange.id}
              platformTagImage={resourceChange.platformTagImage}
              userAvatar={resourceChange.userAvatar}
              timeTagImage={resourceChange.timeTagImage}
              platformTagName={resourceChange.platformTagName}
              changeText={resourceChange.changeText}
              ownedBy={resourceChange.ownedBy}
              amount={resourceChange.amount}
              currency={resourceChange.currency}
              changedTime={resourceChange.changedTime}
              size={resourceChange.size}
            ></ResourceChange>
            <br />
            <br />
          </>
        );
      })}
      <br />
      {/* children, count, color, */}
      {tileDataList.map((tile) => {
        return (
          <>
            <Tile key={tile.id} count={tile.count} color={tile.color}>
              {tile.children}
            </Tile>
          </>
        );
      })}
      {/* resourceName, resourceEnvironment, resourceOwner, resourceHealth,
      resourceService, resourceCriticality, resourceHealthChipColor,
      resourceHealthChipBackgroundColor, resourceServiceChipColor,
      resourceServiceChipBackgroundColor, resourceCriticalityChipColor,
      resourceCriticalityChipBackgroundColor, resourceNameImage, userAvatar, */}
      {ResourceDetailsList.map((resourceDetail) => {
        return (
          <>
            <ResourceDetails
              key={resourceDetail.id}
              resourceName={resourceDetail.resourceName}
              resourceEnvironment={resourceDetail.resourceEnvironment}
              resourceOwner={resourceDetail.resourceOwner}
              resourceHealth={resourceDetail.resourceHealth}
              resourceService={resourceDetail.resourceService}
              resourceCriticality={resourceDetail.resourceCriticality}
              resourceHealthChipColor={resourceDetail.resourceHealthChipColor}
              resourceHealthChipBackgroundColor={
                resourceDetail.resourceHealthChipBackgroundColor
              }
              resourceServiceChipColor={resourceDetail.resourceServiceChipColor}
              resourceServiceChipBackgroundColor={
                resourceDetail.resourceServiceChipBackgroundColor
              }
              resourceCriticalityChipColor={
                resourceDetail.resourceCriticalityChipColor
              }
              resourceCriticalityChipBackgroundColor={
                resourceDetail.resourceCriticalityChipBackgroundColor
              }
              resourceNameImage={resourceDetail.resourceNameImage}
              userAvatar={resourceDetail.userAvatar}
            ></ResourceDetails>
            <br></br>
            <Description list={description.list}>
              {description.text}
            </Description>
            <br></br>
            <IncidentDetails></IncidentDetails>
            <br></br>
            <br />
            <ChangeDetailsChat
              changeDetailsProviderImage={changeDetailsProviderImage}
            ></ChangeDetailsChat>
            <br />
            <br />

            <ChatWindow converstion={messages}></ChatWindow>
            <MessageBoxComponent />
            <SideButtons />
          </>
        );
      })}
    </>
  );
}

export default App;
