import React from "react";
import { Tag } from "../Tag";
import styles from "./IconTag.module.css";
function IconTag({ children, tagImage, divProps, spanProps, imgProps }) {
  // Default class names
  const defaultDivClassName = "icontag-container";
  const defaultSpanClassName = "tag";
  const defaultImgClassName = "tag-image";
  // Merge default class names with provided class names
  const divClassName = `${styles[defaultDivClassName]} ${divProps.className}`;
  const spanClassName = `${styles[defaultSpanClassName]} ${
    spanProps.className || ""
  }`;
  const imgClassName = `${styles[defaultImgClassName]} ${
    imgProps.className || ""
  }`;

  return (
    <div>
      {tagImage ? (
        <div className={divClassName}>
          <img src={tagImage} alt="" {...imgProps} className={imgClassName} />
          <span {...spanProps} className={spanClassName}>
            {children}
          </span>
        </div>
      ) : (
        <Tag />
      )}
    </div>
  );
}

IconTag.defaultProps = {
  children: "Text Here",
  tagImage: "",
  divProps: "",
  spanProps: { className: "" },
  imgProps: { className: "" },
};

export default IconTag;
