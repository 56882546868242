import React from "react";
import styles from "./Description.module.css";

function Description({ children, isOrdered, list, className, ...props }) {
  const ListComponent = isOrdered ? "ol" : "ul";

  return (
    <div className={styles.desc}>
      {children && (
        <>
          {children}
          <br />
        </>
      )}
      <ListComponent>
        {list.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ListComponent>
    </div>
  );
}

export default Description;
