import React from "react";
import Tag from "../../Tag/Tag";
import IconTag from "../../IconTag/IconTag";
import styles from "./Incident.module.css";
import Polygon from "./assets/polygon.svg";
import defaultImage from "../../../assets/defaultImage.jpg";

function Incident({
  children,
  incidentType,
  incidentPlatform,
  incidentTime,
  incidentTagImage,
  className,
  spanClassName,
  tagClassName,
  incidentMessage,
  iconTagClassName,
  ...props
}) {
  const defaultDivClassName = "incident";
  const divClasses = `${styles[defaultDivClassName]} ${className}`;

  const defaultSpanClassName = "incidentText";
  const spanClasses = `${styles[defaultSpanClassName]} ${spanClassName}`;

  const defaultTagClassName = "platformTag";
  const tagClasses = `${styles[defaultTagClassName]} ${tagClassName}`;

  const defaultIconTagClassName = "iconTagText";
  const iconTagClasses = `${styles[defaultIconTagClassName]} ${iconTagClassName}`;

  const defaultSvgClassName = "polygonImg";
  const svgClasses = `${styles[defaultSvgClassName]} ${iconTagClassName}`;

  return (
    <div className={divClasses} {...props}>
      <span className={spanClasses}>
        <img src={Polygon} className={svgClasses} alt="" />
        {incidentType + ": "}
        {children}
      </span>
      <Tag className={tagClasses}>{incidentPlatform}</Tag>
      <IconTag
        tagImage={incidentTagImage}
        spanProps={{ className: iconTagClasses }}
      >
        {incidentTime}
      </IconTag>
    </div>
  );
}

Incident.defaultProps = {
  children: "Text Here",
  incidentType: "Incident Type",
  incidentPlatform: "Incident Platform",
  incidentTime: "Incident Time",
  incidentTagImage: defaultImage,
  className: "",
  spanClassName: "",
  tagClassName: "",
  iconTagClassName: "",
};

export default Incident;
