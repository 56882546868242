import React from "react";
import { IconTag } from "../../IconTag";
import { Tag } from "../../Tag";
import { Chip } from "../../Chip";
import defaultImage from "../../../assets/defaultImage.jpg";
import styles from "./ResourceDetails.module.css";
function ResourceDetails({
  resourceName,
  resourceEnvironment,
  resourceOwner,
  resourceHealth,
  resourceService,
  resourceCriticality,
  resourceHealthChipColor,
  resourceHealthChipBackgroundColor,
  resourceServiceChipColor,
  resourceServiceChipBackgroundColor,
  resourceCriticalityChipColor,
  resourceCriticalityChipBackgroundColor,
  resourceNameImage,
  userAvatar,
}) {
  return (
    <div>
      <br />
      <div className={styles["resource"]}>
        <div className={styles["resource-details"]}>
          <span className={styles["resource-name-text"]}>Resource Name</span>
          <span className={styles["colon-text"]}>:</span>
        </div>
        <IconTag
          tagImage={resourceNameImage}
          divProps={{ className: styles["resource-name-tag"] }}
        >
          {resourceName}
        </IconTag>
      </div>
      <br />
      <div className={styles["resource"]}>
        <div className={styles["resource-details"]}>
          <span className={styles["resource-enviornment-text"]}>
            Environment
          </span>
          <span className={styles["colon-text"]}>:</span>
        </div>
        <Tag className={styles["resource-environment-tag"]}>
          {resourceEnvironment}
        </Tag>
      </div>
      <br />

      <div className={styles["resource"]}>
        <div className={styles["resource-details"]}>
          <span className={styles["resource-owner-text"]}>Owner</span>
          <span className={styles["colon-text"]}>:</span>
        </div>
        <IconTag
          tagImage={userAvatar}
          divProps={{ className: styles["resource-owner-tag"] }}
        >
          {resourceOwner}
        </IconTag>
      </div>
      <br />

      <div className={styles["resource"]}>
        <div className={styles["resource-details"]}>
          <span className={styles["resource-health-text"]}>Health</span>
          <span className={styles["colon-text"]}>:</span>
        </div>
        <Chip
          color={resourceHealthChipColor}
          bgColor={resourceHealthChipBackgroundColor}
          className={styles["health-chip"]}
        >
          {resourceHealth}
        </Chip>
      </div>
      <br />

      <div className={styles["resource"]}>
        <div className={styles["resource-details"]}>
          <span className={styles["resource-name-text"]}>Service</span>
          <span className={styles["colon-text"]}>:</span>
        </div>
        <Chip
          color={resourceServiceChipColor}
          bgColor={resourceServiceChipBackgroundColor}
          className={styles["service-chip"]}
        >
          {resourceService}
        </Chip>
      </div>
      <br />

      <div className={styles["resource"]}>
        <div className={styles["resource-details"]}>
          <span className={styles["resource-criticality-text"]}>
            Criticality
          </span>
          <span className={styles["colon-text"]}>:</span>
        </div>
        <Chip
          color={resourceCriticalityChipColor}
          bgColor={resourceCriticalityChipBackgroundColor}
          className={styles["criticality-chip"]}
        >
          {resourceCriticality}
        </Chip>
      </div>
      <br />
    </div>
  );
}

ResourceDetails.defaultProps = {
  resourceNameImage: defaultImage,
  userAvatar: defaultImage,
  resourceName: "Resource Name",
  resourceEnvironment: "Default Environment",
  resourceOwner: "Resource Owner",
  resourceHealth: "Resource Health",
  resourceService: "Resource Service",
  resourceCriticality: "Resource Criticality",
  resourceHealthChipColor: "#2DA077",
  resourceHealthChipBackgroundColor: "#E7F3F0",
  resourceServiceChipColor: "#2DA077",
  resourceServiceChipBackgroundColor: "#E7F3F0",
  resourceCriticalityChipColor: "#D94F4F",
  resourceCriticalityChipBackgroundColor: "#F5D9DA",
};

export default ResourceDetails;
