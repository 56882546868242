import React from "react";
import styles from "./MessageBox.module.css";
import onepaneLogo from "../Chat/ChatHeader/assets/onepane-logo.svg";
import copilot from "../Chat/ChatHeader/assets/copilot.svg";
import cross from "./assets/cross.svg";
import circle from "./assets/circle.svg";
import star from "./assets/star.svg";

const MessageBoxComponent = ({ emoji, username, message }) => {
  return (
    <>
      <div className={styles["message-box"]}>
        <img
          src={onepaneLogo}
          className={styles["onepane-image"]}
          alt="onepane-logo"
        />
        <img
          src={copilot}
          className={styles["copilot-image"]}
          alt="copilot text"
        />
        <div className={styles["container"]}>
          <img src={circle} className={styles["circle"]} alt="circle" />
          <img src={cross} className={styles["cross"]} alt="close button" />
        </div>

        <div className={styles["text-container"]}>
          <span className={styles["emoji"]}>{emoji}</span>
          <span className={styles["welcome-text"]}>Hi {username}</span>

          <span className={styles["message"]}>{message}</span>
        </div>
        <div className={styles["button-container"]}>
          <span className={styles["learn-more"]}>Learn More</span>
          <div className={styles["lets-go-btn"]}>
            <img src={star} alt="star" />
            <span className={styles["lets-go-text"]}>Let's Go</span>
          </div>
        </div>
        <div className={styles["triangle"]}></div>
        <div className={styles["triangle-helper"]}></div>
      </div>
    </>
  );
};

MessageBoxComponent.defaultProps = {
  emoji: "👋🏻",
  username: "Ashmil",
  message: "Explore our copilot",
};

export default MessageBoxComponent;
