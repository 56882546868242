import React from "react";
import styles from "./Tile.module.css";
function Tile({ children, count, color, className }) {
  // Default class names
  const defaultTileClassName = "tile";
  // Merge default class names with provided class names
  const tileClassName = `${styles[defaultTileClassName]} ${className || ""}`;

  const defaultTileDesingBlockClassName = "tile-design";
  const tileDesignBlockClassName = `${
    styles[defaultTileDesingBlockClassName]
  } ${className || ""}`;

  const defaultTileTextNumberClassName = "tile-text-number";
  const tileTextNumberClassName = `${styles[defaultTileTextNumberClassName]} ${
    className || ""
  }`;

  const defaultTileTextClassName = "tile-text";
  const tileTextClassName = `${styles[defaultTileTextClassName]} ${
    className || ""
  }`;
  return (
    <div className={tileClassName}>
      <div
        className={tileDesignBlockClassName}
        style={{ backgroundColor: `${color}` }}
      ></div>
      <span className={tileTextNumberClassName} style={{ color: `${color}` }}>
        {count}
      </span>
      <span className={tileTextClassName}>{children}</span>
    </div>
  );
}

Tile.defaultProps = {
  children: "Text  Here",
  count: 0,
  color: "#000",
};

export default Tile;
